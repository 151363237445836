<template>
	<div class="row">
		<div class="title">{{ htmldata.title }}</div>
		<div class="content" v-html="htmldata.content"></div>
	</div>
</template>

<script>
	import { getfootdetailApi } from '@/network/api';
	export default {
		data() {
			return {
				typeid: null,
				htmldata: {}
			};
		},
		created() {
			this.typeid = this.$route.query.id;
			this.getdata();
			this.scrollToTop();
		},
		methods: {
			async getdata() {
				let params = {
					id: this.typeid
				};
				const res = await getfootdetailApi(params);
				this.htmldata = res.data;
			},
			scrollToTop() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth' // 平滑滚动
				});
			}
		},
		watch: {
			$route(nv) {
				this.typeid = nv.query.id;
				this.getdata();
				this.scrollToTop();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.row {
		display: flex;
		flex-flow: row wrap;
		font-family: Microsoft YaHei UI, Microsoft YaHei UI;
		color: #000000;
		width: 14.5rem;
		margin: 0 auto;
		p {
			font-weight: 200;
			margin-top: 0;
			margin-bottom: 1.3em;
		}
	}
	.title {
		text-align: center;
		font-size: 0.4rem;
		font-family: Microsoft YaHei UI, Microsoft YaHei UI;
		margin-bottom: 0.4rem;
		font-weight: 100;
		border-bottom: 0.01rem solid #000000;
		width: 100%;
	}
	.content {
		width: 14.5rem;
		margin: 0 auto;
		
	}
</style>
